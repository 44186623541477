module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"UBC Virtual Graduation Okanagan","short_name":"UBCO Graduation","start_url":"/","background_color":"#866B25","theme_color":"#002145","display":"browser","icon":"static/images/icon-ubc-coat-of-arms.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"592b81fa5d3ef0559748d0a9b352864b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WPWHQ5M","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-1463589-3","head":false,"pageTransitionDelay":0,"defer":false,"anonymize":false,"respectDNT":false,"exclude":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
